// ===================================================================
// Import modules
// ===================================================================
import styled from 'styled-components';
import { rem } from 'polished';

// ===================================================================
// Import components
// ===================================================================
import FlexBox from '/component/base/FlexBox/FlexBox';

export const Base = styled(FlexBox).attrs({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center'
})`
  pointer-events: auto;

  &:first-child > .backdrop {
    margin-top: ${rem(24)};
  }

  &:last-child > .backdrop {
    margin-bottom: ${rem(24)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-width: 345px;
  }
`;

export const Backdrop = styled(FlexBox).attrs(() => ({
  alignItems: 'center',
  className: 'backdrop',
  flex: '1',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative'
}))`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: ${rem(42)};
  padding: ${rem(14.5)} ${rem(12)} ${rem(14.5)} ${rem(24)};

  * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const CloseButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: ${rem(16)};
  justify-content: center;
  margin-left: 10px;

  padding: 0;
`;
