import gql from 'graphql-tag';

export const VIEWER_QUERY = gql`
  query Viewer {
    viewer {
      acePrograms {
        id
      }
      activeNotifications {
        id
        analyticsCtaEvent
        analyticsReadEvent
        ctaLink
        ctaText
        description
        subjectLine
        actionedAt
        dismissedAt
        expiredAt
        createdAt
        updatedAt
        notificationType
      }
      contactId
      email
      firstName
      id
      intakeCompleted
      lastName
      membershipDeposits {
        id
      }
      memberships {
        id
      }
      olderNotifications {
        id
        analyticsCtaEvent
        analyticsReadEvent
        ctaLink
        ctaText
        description
        subjectLine
        actionedAt
        dismissedAt
        expiredAt
        createdAt
        updatedAt
        notificationType
      }
      sobrietySchoolPrograms {
        id
        aceStartDate
        aceEndDate
        coachingStartDate
        coachingEndDate
        exitSurveyUrl
        exitSurveyStartDate
        exitSurveyEndDate
        startDate
        endDate
        liveCourseAbsorbId
        resourceCenterUrl
        intakeSurveyUrl
        orientationLivestreamUrl
        weekZeroLessonDate
        weekZeroQaDate
        weekOneLessonDate
        weekOneQaDate
        weekTwoLessonDate
        weekTwoQaDate
        weekThreeLessonDate
        weekThreeQaDate
        weekFourLessonDate
        weekFourQaDate
        weekFiveLessonDate
        weekFiveQaDate
        weekSixLessonDate
        weekSixQaDate
        weekSevenLessonDate
        weekSevenQaDate
        weekEightLessonDate
        weekEightQaDate
        weekEightPlusDate
        weekEightPlusQaDate
        bonusWeekStartDate
        bonusWeekQaDate
        weekOneArchivedVideoUrl
        weekOneArchivedAudioUrl
        weekOneArchivedTranscriptUrl
        weekOneSecondArchivedVideoUrl
        weekOneSecondArchivedAudioUrl
        weekTwoArchivedVideoUrl
        weekTwoArchivedAudioUrl
        weekTwoArchivedTranscriptUrl
        weekThreeArchivedVideoUrl
        weekThreeArchivedAudioUrl
        weekThreeArchivedTranscriptUrl
        weekFourArchivedVideoUrl
        weekFourArchivedAudioUrl
        weekFourArchivedTranscriptUrl
        weekFiveArchivedVideoUrl
        weekFiveArchivedAudioUrl
        weekFiveArchivedTranscriptUrl
        weekSixArchivedVideoUrl
        weekSixArchivedAudioUrl
        weekSixArchivedTranscriptUrl
        weekSevenArchivedVideoUrl
        weekSevenArchivedAudioUrl
        weekSevenArchivedTranscriptUrl
        weekEightArchivedVideoUrl
        weekEightArchivedAudioUrl
        weekEightArchivedTranscriptUrl
        weekEightPlusArchivedVideoUrl
        weekEightPlusArchivedAudioUrl
        weekEightPlusArchivedTranscriptUrl
        bonusWeekArchivedVideoUrl
        bonusWeekArchivedAudioUrl
        bonusWeekArchivedTranscriptUrl
        weekZeroProgramId
        weekOneProgramId
        weekTwoProgramId
        weekThreeProgramId
        weekFourProgramId
        weekFiveProgramId
        weekSixProgramId
        weekSevenProgramId
        weekEightProgramId
        weekEightPlusProgramId
        bonusWeekProgramId
      }
      unconfirmedEmail
    }
  }
`;

export const VIEWER_NOTIFICATIONS_QUERY = gql`
  query Viewer {
    viewer {
      id
      activeNotifications {
        id
        analyticsCtaEvent
        analyticsReadEvent
        ctaLink
        ctaText
        description
        subjectLine
        actionedAt
        dismissedAt
        expiredAt
        createdAt
        updatedAt
        notificationType
      }
      olderNotifications {
        id
        analyticsCtaEvent
        analyticsReadEvent
        ctaLink
        ctaText
        description
        subjectLine
        actionedAt
        dismissedAt
        expiredAt
        createdAt
        updatedAt
        notificationType
      }
    }
  }
`;

export const FAQ_QUERY = gql`
  query Topic($uid: String!) {
    topic: topics(lang: "en-us", uid: $uid) {
      title
      description
      breadcrumb
      body {
        __typename
        ... on TopicsBodyImage___steps {
          fields {
            image
            description
          }
        }
      }
    }
  }
`;

export const PROGRAMS_QUERY = gql`
  query AvailablePrograms($productType: Product!) {
    availablePrograms(productType: $productType) {
      ... on Program {
        id
        name
        price
        remainingEnrollments
        startDate
        endDate
        __typename
      }
      ... on AceProgram {
        id
        name
        price
        remainingEnrollments
        startDate
        endDate
        __typename
      }
      ... on MembershipDeposit {
        id
        name
        price
        remainingEnrollments
        startDate
        endDate
        __typename
      }
    }
  }
`;

export const RESOURCES_HOMEPAGE_QUERY = gql`
  {
    school: allMenus(tags: ["school"]) {
      edges {
        node {
          topic
          topic_section {
            title_of_topic
            topic_link {
              _linkType
              __typename
              ... on Menu {
                topic_section {
                  topic_link {
                    _linkType
                    __typename
                    ... on _ExternalLink {
                      url
                    }
                  }
                  title_of_topic
                }
              }
            }
          }
        }
      }
    }
    faqs: allMenus(tags: ["faqs"]) {
      edges {
        node {
          topic
          topic_section {
            title_of_topic
            topic_link {
              _linkType
              __typename
              ... on Menu {
                topic_section {
                  title_of_topic
                  topic_link {
                    _linkType
                    __typename
                    ... on Topics {
                      title
                      _meta {
                        id
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FAQ_NEXT_ARTICLE_QUERY = gql`
  {
    faqs: allMenus(tags: ["faqs"]) {
      edges {
        node {
          topic_section {
            topic_link {
              __typename
              ... on Menu {
                topic_section {
                  topic_link {
                    __typename
                    ... on Topics {
                      title
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
