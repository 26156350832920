// ===================================================================
// Import modules
// ===================================================================
import React from 'react';

// ===================================================================
// Import components
// ===================================================================
import NotFoundView from '/component/view/NotFoundView/NotFoundView';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(_error, _info) {
    // TODO:
    // Log the error to an error reporting service (Sentry)
  }

  render() {
    if (this.state.hasError) {
      return (
        <NotFoundView
          maxWidth="400px"
          msg={{
            heading: 'base.ErrorBoundary.heading',
            subheading: 'base.ErrorBoundary.subheading'
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
