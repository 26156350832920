const googleAnalytics = {
  init(googleAnalyticsID) {
    /* eslint-disable */
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`,
      'ga'
    );
    /* eslint-enable */
  },

  setConfig(googleAnalyticsID) {
    /* eslint-disable */
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    /* eslint-enable */

    if (googleAnalyticsID) {
      gtag('js', new Date()); // eslint-disable-line no-undef
      gtag('config', googleAnalyticsID); // eslint-disable-line no-undef
    }
  },
  event({ category, action }) {
    if (typeof window.ga !== 'function') return;

    window.ga(
      `gtag_${process.env.GOOGLE_ANALYTICS_TRACKING_ID.replace(
        /-/g,
        '_'
      )}.send`,
      {
        hitType: 'event',
        eventCategory: category,
        eventAction: action
      }
    );
  },
  callbackEvent({ category, action }) {
    return () => {
      googleAnalytics.event({ category, action });
    };
  }
};

export default googleAnalytics;
