// ===================================================================
// Import modules
// ===================================================================
import flatten from 'flat';

// ===================================================================
// Import messages
// ===================================================================
import messagesEn from '/translations/en.json';

// Define user's language. Different browsers have the user locale
// defined on different fields on the `navigator` object, so we make
// sure to account for these different by checking all of them.
const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Remove the region code the language in order since we do not
// currently intend to support multiple regions of the same language
export const languageWithoutRegionCode = language.split(/[-_]/)[0];

// This really should use some logic to dynamically use/make/etc the
// right locale but for now we just have english
export const messages = {
  en: flatten(messagesEn)
};
