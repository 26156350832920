// =============================================================================
// Import modules
// =============================================================================
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { VIEWER_QUERY } from '/graphql/queries';

// =============================================================================
// Import components
// =============================================================================
import { Route, Redirect } from 'react-router-dom';

// =============================================================================
// Import constants
// =============================================================================
import { UNAUTHENTICATED_ROUTE } from '/constant/routes.constant';

export const AuthenticatedRoute = ({
  allowGuestUser = false,
  component: Component,
  ...props
}) => {
  const { loading, error, data } = useQuery(VIEWER_QUERY, {
    fetchPolicy: 'network-only'
  });

  if (loading) return null;

  if (error || (!data.viewer && !allowGuestUser)) {
    return <Redirect push to={UNAUTHENTICATED_ROUTE} />;
  }

  return <Route {...props} component={Component} />;
};

export default AuthenticatedRoute;
