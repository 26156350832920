// ===================================================================
// Import modules
// ===================================================================
import React, { Suspense, lazy } from 'react';
import { hot } from 'react-hot-loader';
import flowRight from 'lodash/fp/flowRight';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

// ===================================================================
// Import provider components
// ===================================================================
import IntlProvider from '/component/provider/IntlProvider/IntlProvider';
import ThemeProvider from '/component/provider/ThemeProvider/ThemeProvider';
import BannerProvider from '/component/provider/BannerProvider/BannerProvider';
import StripeProvider from '/component/provider/StripeProvider/StripeProvider';
import ApolloProvider from '/component/provider/ApolloProvider/ApolloProvider';
import ErrorBoundary from '/component/base/ErrorBoundary/ErrorBoundary';

// ===================================================================
// Import router components
// ===================================================================
import AuthenticatedLoginRoute from '/component/router/AuthenticatedLoginRoute/AuthenticatedLoginRoute';
import AuthenticatedRoute from '/component/router/AuthenticatedRoute/AuthenticatedRoute';
import DefaultRedirect from '/component/router/DefaultRedirect/DefaultRedirect';
import { LastLocationProvider } from 'react-router-last-location';

// ===================================================================
// Import view components
// ===================================================================
const PaymentView = lazy(() =>
  import('/component/view/PaymentView/PaymentView')
);
const AcePaymentView = lazy(() =>
  import('/component/view/AcePaymentView/AcePaymentView')
);
const LoginView = lazy(() => import('/component/view/LoginView/LoginView'));
const NotFoundView = lazy(() =>
  import('/component/view/NotFoundView/NotFoundView')
);
const ConfirmEmailView = lazy(() =>
  import('/component/view/ConfirmEmailView/ConfirmEmailView')
);
const PasswordResetView = lazy(() =>
  import('/component/view/PasswordResetView/PasswordResetView')
);
const AccountSettingsView = lazy(() =>
  import('/component/view/AccountSettingsView/AccountSettingsView')
);
const LmsAuthView = lazy(() =>
  import('/component/view/LmsAuthView/LmsAuthView')
);
const DashboardView = lazy(() =>
  import('/component/view/DashboardView/DashboardView')
);
const ArchivedDashboardView = lazy(() =>
  import(
    '/component/view/DashboardView/ArchivedDashboardView/ArchivedDashboardView'
  )
);
const ComingSoonView = lazy(() =>
  import('/component/view/ComingSoonView/ComingSoonView')
);
const SupportView = lazy(() =>
  import('/component/view/SupportView/SupportView')
);
const BreathingExerciseView = lazy(() =>
  import('/component/view/BreathingExerciseView/BreathingExerciseView')
);
const OnboardingView = lazy(() =>
  import('/component/view/OnboardingView/OnboardingView')
);
const HomeView = lazy(() => import('/component/view/HomeView/HomeView'));
const MembershipHomeView = lazy(() =>
  import('/component/view/MembershipHomeView/MembershipHomeView')
);
const MembershipPaymentView = lazy(() =>
  import('/component/view/MembershipPaymentView/MembershipPaymentView')
);
const ScholarshipView = lazy(() =>
  import('/component/view/ScholarshipView/ScholarshipView')
);
const ReplayView = lazy(() => import('/component/view/ReplayView/ReplayView'));
const ResourcesRouterView = lazy(() =>
  import('/component/view/ResourcesRouterView/ResourcesRouterView')
);

const PlaceholderView = lazy(() =>
  import('/component/view/PlaceholderView/PlaceholderView')
);

// Redirect all requests with one or more trailing slashes to the path
// with slashes removed. This enforces a canonical URL for each view
// and avoids avoids issues with path concatenation introducing
// redundant slashes.
const trailingSlashRedirect = (
  <Route
    exact
    path="/:url/"
    render={(props) => (
      <Redirect to={props.location.pathname.replace(/\/+$/, '')} />
    )}
    strict
  />
);

const withWrapper = (Wrapper, wrapperProps = {}) => (children) => (
  <Wrapper {...wrapperProps}>{children}</Wrapper>
);

export const Routes = () => (
  <Suspense fallback={null}>
    <Switch>
      {trailingSlashRedirect}
      <AuthenticatedRoute component={DefaultRedirect} exact path="/" />
      <AuthenticatedRoute
        component={AccountSettingsView}
        path="/settings"
        withMenu
      />
      <AuthenticatedRoute
        component={ComingSoonView}
        exact
        path="/coming_soon"
        withMenu
      />
      <AuthenticatedRoute
        component={DashboardView}
        exact
        path="/dashboard/:programId?"
        withMenu
      />
      <AuthenticatedRoute
        component={ArchivedDashboardView}
        exact
        path="/archived-dashboard/:programId?"
        withMenu
      />
      <AuthenticatedRoute
        component={ReplayView}
        path="/archived-dashboard/:programId/:weekId"
      />
      <AuthenticatedRoute
        component={ReplayView}
        path="/dashboard/:programId/:weekId"
      />
      <AuthenticatedRoute
        component={BreathingExerciseView}
        path="/breathing_exercise"
      />
      <AuthenticatedRoute component={LmsAuthView} path="/lms_authenticate" />
      <Route component={ScholarshipView} path="/equity_scholarship" />
      <Route component={OnboardingView} path="/onboarding" />
      <Route component={PaymentView} path="/program" />
      <AuthenticatedLoginRoute
        component={AcePaymentView}
        path="/ace_purchase"
      />
      <AuthenticatedRoute component={SupportView} path="/support" withMenu />
      <AuthenticatedRoute
        allowGuestUser
        component={MembershipHomeView}
        exact
        path="/home"
      />
      <Route component={MembershipPaymentView} path="/membership" />
      <AuthenticatedRoute
        allowGuestUser
        component={HomeView}
        exact
        path="/program-school"
      />
      <AuthenticatedRoute
        allowGuestUser
        component={LoginView}
        exact
        path="/login"
      />
      <Route component={ConfirmEmailView} exact path="/email_confirm" />
      <Route component={PasswordResetView} exact path="/password_reset" />
      <AuthenticatedRoute
        component={PlaceholderView}
        path="/community"
        withMenu
      />
      <AuthenticatedRoute component={ResourcesRouterView} path="/resources" />
      <Route component={NotFoundView} />
    </Switch>
  </Suspense>
);

export const BaseContent = () =>
  flowRight([
    withWrapper(ApolloProvider),
    withWrapper(IntlProvider),
    withWrapper(ThemeProvider),
    withWrapper(BannerProvider),
    withWrapper(ErrorBoundary),
    withWrapper(StripeProvider),
    withWrapper(Router),
    withWrapper(LastLocationProvider)
  ])(<Routes />);

export const AppRoot = () => (
  <Router>
    <BaseContent />
  </Router>
);

export default hot(module)(AppRoot);

export function moduleHotAccept(mod) {
  if (mod && mod.hot && mod.hot.status() === 'apply') {
    // eslint-disable-next-line no-console
    console.log('🚒  Hot reload AppRoot');
  }
}

moduleHotAccept(module);
