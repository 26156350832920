// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';

// ===================================================================
// Constants
// ===================================================================
const DEFAULT_LOGO_SIZE = 28;

const TempestLogo = ({ color, size, ...otherProps }) => {
  /* eslint-disable max-len */
  return (
    <svg
      fill="none"
      height={size}
      style={{ width: rem(size) }}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}>
      <path
        d="M16.0678113,16.6943194 C14.1923521,16.7014221 13.2320661,18.1335493 13.2320661,19.2924098 C13.2320661,21.2101369 14.6271951,21.8970421 15.8568479,21.8867619 C16.4682113,21.8815283 16.938246,21.5940562 17.1226284,21.4817215 L17.6329091,21.6086354 C16.9447977,22.1693743 16.135387,22.4480616 15.0640971,22.4480616 C12.8926901,22.4480616 11.1082054,20.7766857 11.1082054,18.4984035 C11.1082054,16.2201212 12.9460393,14.9388327 13.956118,14.5315494 L13.9250444,7.14418779 L17.987073,7.13914113 L18.3270106,5.37449572 L13.9226109,5.37954237 C13.9102564,2.34912229 13.9014584,0 13.9014584,0 L13.3696509,0 C12.095634,2.93453373 8.65751049,5.87785237 5,6.60307472 L5.00224628,7.14175792 L7.44170975,7.1342814 L7.46978829,16.7118892 C7.46978829,21.9562935 10.2355244,24 13.8643948,24 C16.7658438,24 19.0198017,22.2725503 19.0198017,19.7383841 C19.0205505,17.72178 17.4766052,16.6890858 16.0678113,16.6943194 Z"
        fill={color}
        id="TempestLogo"
      />
    </svg>
  );
  /* eslint-enable max-len */
};

TempestLogo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

TempestLogo.defaultProps = {
  color: 'currentColor',
  size: DEFAULT_LOGO_SIZE
};

export default TempestLogo;
