// ===================================================================
// Import modules
// ===================================================================
import './config/rollbar.config';
import React from 'react';
import ReactDOM from 'react-dom';
import FacebookPixel from './pixels/facebookPixel';
import GoogleAnalytics from './pixels/googleAnalytics';

// ===================================================================
// Import components
// ===================================================================
import '@babel/polyfill';
import 'url-search-params-polyfill';
import AppRoot from '/component/root/AppRoot/AppRoot';
import './base.css';

// Initialize Facebook Pixel tracking
FacebookPixel.init(process.env.FACEBOOK_PIXEL_ID);
FacebookPixel.trackPageView();

// Initialize Google Analytics tracking
GoogleAnalytics.init(process.env.GOOGLE_ANALYTICS_TRACKING_ID);
GoogleAnalytics.setConfig(process.env.GOOGLE_ANALYTICS_TRACKING_ID);

ReactDOM.render(<AppRoot />, document.getElementById('root'));
