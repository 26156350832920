// =============================================================================
// Import modules
// =============================================================================
import React from 'react';
import { Redirect } from 'react-router-dom';

// =============================================================================
// Import utilities
// =============================================================================
import { getDefaultUserRoute } from '/util/auth.util';

export const DefaultRedirect = () => (
  <Redirect push to={getDefaultUserRoute()} />
);

export default DefaultRedirect;
