// ===================================================================
// Import constants
// ===================================================================
import {
  AUTHENTICATED_ROUTE,
  UNAUTHENTICATED_ROUTE
} from '/constant/routes.constant';

import { AUTH_DATA_KEY } from '/config/localStorage.config';

// ===================================================================
// Import modules
// ===================================================================
import jwtDecode from 'jwt-decode';

export function parseAuthToken(token) {
  const { sub, exp } = jwtDecode(token);
  const date = new Date(0);

  date.setUTCSeconds(exp);
  const expiresAt = date.toISOString();

  return { expiresAt, userId: sub };
}

export function parseAuthTokenPayload(jwt) {
  const { expiresAt, userId } = parseAuthToken(jwt);

  if (!userId) {
    throw new Error('Authentication required.');
  }

  return { expiresAt, userId };
}
export const isAuthenticated = () => {
  const authData = JSON.parse(localStorage.getItem(AUTH_DATA_KEY));
  if (authData === null) {
    return false;
  }
  const expiry = Date.parse(authData.expiresAt);

  if (authData.userId && expiry > Date.now()) {
    return true;
  }

  return false;
};

export const getDefaultUserRoute = () => {
  return isAuthenticated() ? AUTHENTICATED_ROUTE : UNAUTHENTICATED_ROUTE;
};
