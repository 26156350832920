// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import { Normalize as NormalizeStyles } from 'styled-normalize';
import {
  createGlobalStyle,
  ThemeProvider as BaseProvider
} from 'styled-components';
import { lighten } from 'polished';

// ===================================================================
// Import config
// ===================================================================
import * as theme from '/config/theme.config';

const GlobalStyles = createGlobalStyle`
  #root {
    height: 100%
  }

  *:focus {
    outline-color: ${lighten(0.4, theme.colors.grey)};
  }

  * {
    -webkit-overflow-scrolling: touch;
  }

  button {
    background-color: transparent;
  }
`;

class ThemeProvider extends React.PureComponent {
  render() {
    const { children } = this.props;
    return (
      <BaseProvider theme={theme}>
        <React.Fragment>
          <NormalizeStyles />
          <GlobalStyles />
          {children}
        </React.Fragment>
      </BaseProvider>
    );
  }
}

export default ThemeProvider;
