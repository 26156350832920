// ===================================================================
// Import modules
// ===================================================================
import React, { useEffect } from 'react';
import { rem } from 'polished';
import { X as CloseIcon } from 'react-feather';

// ===================================================================
// Import components
// ===================================================================
import Text from '/component/base/Text/Text';
import { Base, Backdrop, CloseButton } from './StandardBanner.style';

function StandardBanner({ banner: { message, timeout }, closeBanner }) {
  useEffect(() => {
    if (timeout) {
      const interval = setTimeout(() => {
        closeBanner();
      }, timeout);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <Base>
      <Backdrop>
        <Text maxWidth="281px" textAlign="center" textStyle="paragraphSmall">
          {message}
        </Text>
        <CloseButton data-testid="closeBanner" onClick={closeBanner}>
          <CloseIcon size={rem(16)} style={{ width: rem(16) }} />
        </CloseButton>
      </Backdrop>
    </Base>
  );
}

export default StandardBanner;
