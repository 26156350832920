// ===================================================================
// Import modules
// ===================================================================
import { rem } from 'polished';
import styled from 'styled-components';

export const HeadingContainer = styled.div`
  margin-bottom: ${({ theme, headingStyle }) =>
    rem(theme.headingStyles[headingStyle].bottomMargin)};
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme, headingStyle }) => {
      return rem(theme.headingStyles[headingStyle].mobileBottomMargin);
    }};
  }
  margin-top: ${({ marginTop }) => marginTop && rem(marginTop)};
  width: ${({ width }) => width && rem(width)};
`;

export const SubHeadingContainer = styled.div`
  margin-left: ${({ theme, headingStyle, textAlign }) => {
    if (textAlign === 'center' || textAlign === 'right') {
      return rem(theme.headingStyles[headingStyle].subHeadingMargin);
    }
    return null;
  }};
  margin-right: ${({ theme, headingStyle, textAlign }) => {
    if (textAlign === 'center' || textAlign === 'left') {
      return rem(theme.headingStyles[headingStyle].subHeadingMargin);
    }
    return null;
  }};
  margin-top: ${({ theme, headingStyle }) =>
    rem(theme.headingStyles[headingStyle].innerMargin)};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-left: ${({ theme, headingStyle }) => {
      return rem(theme.headingStyles[headingStyle].mobileSubHeadingMargin);
    }};
    margin-right: ${({ theme, headingStyle }) => {
      return rem(theme.headingStyles[headingStyle].mobileSubHeadingMargin);
    }};
  }
`;
