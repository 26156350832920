// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/fp/isFunction';
import {
  BasePropTypes,
  StringPropTypes,
  NumberPropTypes
} from '/proptypes/styledPropTypes';

// ===================================================================
// Import styles
// ===================================================================
import { TextDiv, TextSpan } from './Text.style';

// ===================================================================
// Import config
// ===================================================================
import { textStyles } from '/config/theme.config';

const Text = (props) => {
  const { inline, onClick } = props;
  const Component = inline ? TextSpan : TextDiv;
  const isClickable = isFunction(onClick);

  return <Component {...props} isClickable={isClickable} />;
};

Text.propTypes = {
  /** styled-systems space */
  /** https://styled-system.com/table#space */

  /** (short name) margin all sides */
  m: BasePropTypes,
  /** (short name) margin top */
  mt: BasePropTypes,
  /** (short name) margin right */
  mr: BasePropTypes,
  /** (short name) margin bottom */
  mb: BasePropTypes,
  /** (short name) margin left */
  ml: BasePropTypes,
  /** (short name) margin top and bottom */
  my: BasePropTypes,
  /** (short name) margin left and right */
  mx: BasePropTypes,
  /** margin all sides */
  margin: BasePropTypes,
  /** margin top */
  marginTop: BasePropTypes,
  /** margin right */
  marginRight: BasePropTypes,
  /** margin bottom */
  marginBottom: BasePropTypes,
  /** margin left */
  marginLeft: BasePropTypes,
  /** (short name) padding all sides */
  p: BasePropTypes,
  /** (short name) padding top */
  pt: BasePropTypes,
  /** (short name) padding right */
  pr: BasePropTypes,
  /** (short name) padding bottom */
  pb: BasePropTypes,
  /** (short name) padding left */
  pl: BasePropTypes,
  /** (short name) padding top and bottom */
  py: BasePropTypes,
  /** (short name) padding left and right */
  px: BasePropTypes,
  /** padding all sides */
  padding: BasePropTypes,
  /** padding top */
  paddingTop: BasePropTypes,
  /** padding right */
  paddingRight: BasePropTypes,
  /** padding bottom */
  paddingBottom: BasePropTypes,
  /** padding left */
  paddingLeft: BasePropTypes,

  /** styled-systems color */
  /** https://styled-system.com/table#color */

  /** color */
  color: StringPropTypes,
  /** background color */
  backgroundColor: StringPropTypes,
  /** opacity */
  opacity: NumberPropTypes,

  /** styled-systems typography */
  /** https://styled-system.com/table#typography */

  /** font family */
  fontFamily: StringPropTypes,
  /** font size */
  fontSize: BasePropTypes,
  /** font weight */
  fontWeight: BasePropTypes,
  /** line height */
  lineHeight: BasePropTypes,
  /** letter spacing */
  letterSpacing: BasePropTypes,
  /** text align */
  textAlign: StringPropTypes,
  /** font style */
  fontStyle: StringPropTypes,

  /** styled-systems layout */
  /** https://styled-system.com/table#layout */

  /** width */
  width: BasePropTypes,
  /** height */
  height: BasePropTypes,
  /** min width */
  minWidth: BasePropTypes,
  /** max width */
  maxWidth: BasePropTypes,
  /** min height */
  minHeight: BasePropTypes,
  /** max height */
  maxHeight: BasePropTypes,
  /** size */
  size: BasePropTypes,
  /** display */
  display: StringPropTypes,
  /** vertical align */
  verticalAlign: NumberPropTypes,
  /** overflow */
  overflow: StringPropTypes,

  /** styled-systems border */
  /** https://styled-system.com/table#border */

  /** border */
  border: StringPropTypes,
  /** border width */
  borderWidth: BasePropTypes,
  /** border style */
  borderStyle: StringPropTypes,
  /** border color */
  borderColor: StringPropTypes,
  /** border radius */
  borderRadius: BasePropTypes,
  /** border top */
  borderTop: BasePropTypes,
  /** border right */
  borderRight: BasePropTypes,
  /** border bottom */
  borderBottom: BasePropTypes,
  /** border left */
  borderLeft: BasePropTypes,
  /** border left and right */
  borderX: BasePropTypes,
  /** border top and bottom */
  borderY: BasePropTypes,

  alpha: PropTypes.number,
  cursor: PropTypes.string,
  children: PropTypes.node,
  inline: PropTypes.bool,
  mixBlendMode: PropTypes.string,
  onClick: PropTypes.func,
  textStyle: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(textStyles)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(textStyles))),
    PropTypes.object
  ]),
  textTransform: PropTypes.string,
  wordWrap: PropTypes.string
};

Text.defaultProps = {
  alpha: 1,
  color: 'black'
};

export default Text;
