// ===================================================================
// Import modules
// ===================================================================
import styled from 'styled-components';

export const BannersContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
`;
