// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import notFoundImage from '/asset/image/404cat.jpg';
import { breakpoints, colors } from '/config/theme.config';
import useMedia from '/util/useMedia';

// ===================================================================
// Import components
// ===================================================================
import BaseLayout from '/component/layout/BaseLayout/BaseLayout';
import Heading from '/component/base/Heading/Heading';
import FlexBox from '/component/base/FlexBox/FlexBox';
import Box from '/component/base/Box/Box';
import { Link } from 'react-router-dom';
import TempestLogo from '/component/base/TempestLogo/TempestLogo';
import Text from '/component/base/Text/Text';

// ===================================================================
// Import constants
// ===================================================================
import { TEMPEST_EMAIL_ADDRESS } from '/constant/email.constant';

export const NotFoundView = ({ intl, msg, maxWidth }) => {
  const mobile = useMedia(`(max-width: ${breakpoints.mobile})`, false);

  return (
    <BaseLayout
      backgroundImage={notFoundImage}
      backgroundPositionHorizontal={mobile ? -140 : 0}
      borderColor="transparent"
      color="white"
      opacity={0.85}>
      <div
        css={`
          left: 30px;
          position: absolute;
          top: 30px;
        `}>
        <Link to="/login">
          <TempestLogo color={colors.black} />
        </Link>
      </div>
      <FlexBox alignItems="center" height="100%" justifyContent="center">
        <Box maxWidth={maxWidth ? maxWidth : '340px'} mx="5">
          <Heading
            headingStyle="displayModal"
            text={intl.formatMessage({
              id: msg ? msg.heading : 'view.NotFoundView.heading'
            })}
            textAlign="center"
          />
          <Text
            marginBottom="30px"
            marginTop="20px"
            textAlign="center"
            textStyle="paragraph">
            <FormattedMessage
              id={msg ? msg.subheading : 'view.NotFoundView.subheading'}
              values={{
                back: (
                  <Link css={{ color: colors.black }} to="/">
                    back
                  </Link>
                ),
                homepage: (
                  <Link css={{ color: colors.black }} to="/login">
                    homepage
                  </Link>
                ),
                email: (
                  <a
                    css={{ color: colors.black }}
                    href={`mailto:${TEMPEST_EMAIL_ADDRESS}`}>
                    {TEMPEST_EMAIL_ADDRESS}
                  </a>
                )
              }}
            />
          </Text>
        </Box>
      </FlexBox>
    </BaseLayout>
  );
};

export default injectIntl(NotFoundView);
