export const breakpoints = ['640px', '769px', '1024px', '1440px'];

breakpoints.mobile = breakpoints[0];
breakpoints.tablet = breakpoints[1];
breakpoints.desktop = breakpoints[2];
breakpoints.desktopLarge = breakpoints[3];

breakpoints.mobileHorizontal = '812px'; // this comes from Chrome Emulator

export const colors = {
  black: '#231F20',
  blueLight: '#F5F7F8',
  blue: '#ECF5FF',
  cream: '#FEF9F3',
  gold: '#EBC853',
  green: '#58BB66',
  grey: '#272121',
  greyDark: '#868485',
  greyMedium: '#CBCCCD',
  greyLight: '#E9E9E9',
  greyLightest: '#F4F4F4',
  navy: '#222B4B',
  navyLight: '#38405D',
  red: '#D23A23',
  pink: '#F5DBD0',
  pinkMembership: '#FDF8F1',
  purple: '#E1E3F8',
  violet: '#C6C8EB',
  white: '#FFFFFF',
  transparent: 'transparent'
};

export const gradientColors = [
  '#F5DBD0',
  '#F5DBD1',
  '#F0DDDA',
  '#EBDFE3',
  '#E6E1ED',
  '#E1E3F8',
  '#DCDEF5',
  '#D6D8F3',
  '#D0D2F0',
  '#C6C8EB'
];

export const containerSizes = {
  xSmall: 275,
  small: 320,
  smallMedium: 520,
  medium: 1000,
  large: 1144,
  xLarge: 1440
};

export const containerPadding = 20;

export const spacings = {
  small: 20,
  medium: 30,
  large: 50,
  page: 148
};

export const headingStyles = {
  default: {
    bottomMargin: '48px',
    headingStyle: 'displayExtraLarge',
    mobileHeadingStyle: 'displayMidLarge',
    innerMargin: '16px',
    subHeadingStyle: 'displayMedium',
    subHeadingMargin: '0px'
  },
  displayMobileHorizontal: {
    bottomMargin: '48px',
    headingStyle: 'displayMidLarge',
    innerMargin: '16px',
    subHeadingStyle: 'displayMedium',
    subHeadingMargin: '0px'
  },
  displayExtraLargeReg: {
    bottomMargin: '20px',
    headingStyle: 'displayExtraLargeAlt',
    mobileHeadingStyle: 'displayMidLarge',
    innerMargin: '16px',
    subHeadingStyle: 'displayMedium',
    mobileSubHeadingStyle: 'paragraph',
    subHeadingMargin: '0px'
  },
  displayExtraLargeRegAlt: {
    bottomMargin: '20px',
    headingStyle: 'displayExtraLargeAlt',
    mobileHeadingStyle: 'displayMidLarge',
    innerMargin: '24px',
    subHeadingStyle: 'displayMedSmallAlt',
    mobileSubHeadingStyle: 'displayMedSmallAlt',
    subHeadingMargin: '0px'
  },
  displayExtraLargeAlt: {
    bottomMargin: '20px',
    headingStyle: 'displayExtraLargeAlt',
    mobileHeadingStyle: 'displayMidLarge',
    innerMargin: '16px',
    subHeadingStyle: 'displayMediumMed',
    mobileSubHeadingStyle: 'paragraphAlt',
    subHeadingMargin: '0px'
  },
  displayLarge: {
    bottomMargin: '0px',
    headingStyle: 'displayLargeAlt',
    mobileHeadingStyle: 'displayMediumAlt',
    innerMargin: '16px',
    subHeadingStyle: 'paragraph'
  },
  displayLargeAlt: {
    bottomMargin: '8px',
    headingStyle: 'displayLargeAlt',
    mobileHeadingStyle: 'displayLargeAltMobile',
    innerMargin: '16px',
    subHeadingStyle: 'displayMedium',
    mobileSubHeadingStyle: 'paragraph',
    subHeadingMargin: '0px'
  },
  displayMedium: {
    bottomMargin: '0px',
    headingStyle: 'displayMediumAlt',
    innerMargin: '16px',
    subHeadingStyle: 'paragraphSerif',
    subHeadingMargin: '0px'
  },
  displayMediumReg: {
    bottomMargin: '0px',
    headingStyle: 'displayMediumAlt',
    mobileHeadingStyle: 'displaySmall',
    innerMargin: '16px',
    subHeadingStyle: 'paragraphSerifReg',
    subHeadingMargin: '0px'
  },
  displayMediumLarge: {
    bottomMargin: '0px',
    headingStyle: 'displayMediumLargeBold',
    mobileHeadingStyle: 'displayMobileReg',
    innerMargin: '16px',
    subHeadingStyle: 'paragraphSmall',
    subHeadingMargin: '0px'
  },
  displayExtraSmall: {
    bottomMargin: '0px',
    headingStyle: 'paragraphSmall',
    innerMargin: '0px',
    subHeadingStyle: 'paragraphSmall',
    subHeadingMargin: '0px'
  },
  displayModal: {
    bottomMargin: '42px',
    mobileBottomMargin: '0px',
    headingStyle: 'displayExtraLargeAlt',
    mobileHeadingStyle: 'displayMobileLarge',
    innerMargin: '20px',
    subHeadingStyle: 'displayMediumSerif',
    mobileSubHeadingStyle: 'paragraph',
    subHeadingMargin: '54px',
    mobileSubHeadingMargin: '0px'
  }
};

export const fonts = {
  topolBold: 'Topol Bold',
  untitledSansReg: 'Untitled Sans Regular',
  untitledSansMed: 'Untitled Sans Medium',
  untitledSerifReg: 'Untitled Serif Regular',
  untitledSerifMed: 'Untitled Serif Medium'
};

export const textStyles = {
  displayExtraLarge: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '52px',
    fontWeight: 'normal',
    lineHeight: '48px',
    letterSpacing: -1.8
  },
  displayExtraLargeAlt: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '52px',
    fontWeight: 'normal',
    lineHeight: '48px',
    letterSpacing: -1.8
  },
  displayMobileLarge: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '38px',
    fontWeight: 'normal',
    lineHeight: '36px',
    letterSpacing: -1.8
  },
  displayMidLarge: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '38px',
    fontWeight: 'normal',
    lineHeight: '36px',
    letterSpacing: -1
  },
  displayLarge: {
    fontFamily: fonts.untitledSansMed,
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '37px',
    letterSpacing: -0.4
  },
  displayMobileMedium: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: '26px',
    letterSpacing: -0.8
  },
  displayMobileReg: {
    fontFamily: fonts.untitledSerifReg,
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: '26px',
    letterSpacing: -0.8
  },
  displayMobileExtraLargeAlt: {
    fontFamily: fonts.topolBold,
    fontSize: '40px',
    fontWeight: 'normal',
    lineHeight: '31px',
    letterSpacing: 0,
    textTransform: 'uppercase'
  },
  displayLargeAlt: {
    fontFamily: fonts.topolBold,
    fontSize: '26px',
    fontWeight: 'normal',
    lineHeight: '31px',
    letterSpacing: 0,
    textTransform: 'uppercase'
  },
  displayLargeAltMobile: {
    fontFamily: fonts.topolBold,
    fontSize: '22px',
    fontWeight: 'normal',
    lineHeight: '27px',
    letterSpacing: 0,
    textTransform: 'uppercase'
  },
  displayMediumLarge: {
    fontFamily: fonts.untitledSerifReg,
    fontSize: '32px',
    fontWeight: 'normal',
    lineHeight: '37px',
    letterSpacing: -0.4
  },
  displayMediumLargeBold: {
    fontFamily: fonts.untitledSerifReg,
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '37px',
    letterSpacing: -0.4
  },
  displayMediumLargeAlt: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '32px',
    fontWeight: 'normal',
    lineHeight: '37px',
    letterSpacing: -0.4
  },
  displayMedium: {
    fontFamily: fonts.untitledSansReg,
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '25px',
    letterSpacing: -0.4
  },
  displayMediumSerif: {
    fontFamily: fonts.untitledSerifReg,
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '25px',
    letterSpacing: -0.4
  },
  displayMediumSerifNoSpacing: {
    fontFamily: fonts.untitledSerifReg,
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '25px',
    letterSpacing: 0
  },
  displayMediumMed: {
    fontFamily: fonts.untitledSansMed,
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '25px',
    letterSpacing: -0.4
  },
  displayMediumAlt: {
    fontFamily: fonts.topolBold,
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: 0.15,
    textTransform: 'uppercase'
  },
  displayMedSmallAlt: {
    fontFamily: fonts.topolBold,
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '15px',
    letterSpacing: 0.5,
    textTransform: 'uppercase'
  },
  displaySmall: {
    fontFamily: fonts.topolBold,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '16px',
    letterSpacing: 0.15,
    textTransform: 'uppercase'
  },
  displayExtraSmall: {
    fontFamily: fonts.topolBold,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '15px',
    letterSpacing: 0.5,
    textTransform: 'uppercase'
  },
  paragraph: {
    fontFamily: fonts.untitledSansReg,
    fontSize: '16px',
    fontWeight: 'lighter',
    lineHeight: '24px',
    letterSpacing: -0.04
  },
  paragraphAlt: {
    fontFamily: fonts.untitledSansMed,
    fontSize: '16px',
    fontWeight: 'lighter',
    lineHeight: '24px',
    letterSpacing: -0.04
  },
  paragraphSerif: {
    fontFamily: fonts.untitledSerifMed,
    fontSize: '16px',
    fontWeight: 'lighter',
    lineHeight: '23px',
    letterSpacing: 0
  },
  paragraphSerifReg: {
    fontFamily: fonts.untitledSerifReg,
    fontSize: '16px',
    fontWeight: 'lighter',
    lineHeight: '23px',
    letterSpacing: 0
  },
  paragraphSmall: {
    fontFamily: fonts.untitledSansMed,
    fontSize: '12px',
    fontWeight: 'lighter',
    lineHeight: '14px',
    letterSpacing: 0
  },
  paragraphSmallReg: {
    fontFamily: fonts.untitledSansReg,
    fontSize: '12px',
    fontWeight: 'lighter',
    lineHeight: '14px',
    letterSpacing: 0
  }
};
