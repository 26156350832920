// ===================================================================
// Import modules
// ===================================================================
import PropTypes from 'prop-types';
import React from 'react';

// ===================================================================
// Import styles
// ===================================================================
import {
  BackgroundImage,
  BaseLayoutContainer,
  MainContent
} from './BaseLayout.style';

// ===================================================================
// Import config
// ===================================================================
import { colors } from '/config/theme.config';

export const BaseLayout = ({
  backgroundColor,
  backgroundImage,
  children,
  color,
  borderColor,
  withMenu,
  gradient,
  opacity,
  backgroundPositionHorizontal,
  backgroundPositionVertical
}) => (
  <BackgroundImage
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    backgroundPositionHorizontal={backgroundPositionHorizontal}
    backgroundPositionVertical={backgroundPositionVertical}>
    <BaseLayoutContainer borderColor={borderColor} withMenu={withMenu}>
      <MainContent color={color} gradient={gradient} opacity={opacity}>
        {children}
      </MainContent>
    </BaseLayoutContainer>
  </BackgroundImage>
);

BaseLayout.propTypes = {
  backgroundImage: PropTypes.node,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.keys(colors)),
  borderColor: PropTypes.oneOf(Object.keys(colors)),
  menu: PropTypes.node,
  opacity: PropTypes.number,
  backgroundPositionHorizontal: PropTypes.node,
  backgroundPositionVertical: PropTypes.node
};

export default BaseLayout;
