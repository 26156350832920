import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { BatchHttpLink } from 'apollo-link-batch-http';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../../../../apiFragmentTypes.json';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { API_URL } from '/config/environment.config.js';
import { AUTH_TOKEN_KEY, AUTH_DATA_KEY } from '/config/localStorage.config';
import 'unfetch/polyfill';

export const errorHandling = ({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_DATA_KEY);

    window.location = '/login';
  }
};

const errorLink = onError(errorHandling);

export const request = (_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }
  };
};

const authLink = setContext(request);

const batchLink = new BatchHttpLink({
  uri: `${API_URL}/graphql`,
  batchInterval: 20
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, batchLink]),
  cache: new InMemoryCache({ fragmentMatcher })
});

export default ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
