// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { messages } from './MessagesProvider';

const IntlProvider = (props) => (
  <ReactIntlProvider
    defaultLocale="en"
    locale="en"
    messages={messages.en}
    {...props}
  />
);

export default IntlProvider;
