// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./asset/font/Topol-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./asset/font/UntitledSans-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./asset/font/UntitledSans-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./asset/font/UntitledSerif-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./asset/font/UntitledSerif-Medium.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Topol Bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: 'Untitled Sans Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: 'Untitled Sans Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n  font-family: 'Untitled Serif Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n  font-family: 'Untitled Serif Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n\nhtml,\nbody {\n  font-family: 'Untitled Serif Regular', Arial, sans-serif;\n  height: 100%;\n}\n", ""]);
// Exports
module.exports = exports;
