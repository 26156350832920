// ===================================================================
// Import modules
// ===================================================================
import { rem, rgba } from 'polished';
import styled from 'styled-components';

// ===================================================================
// Import components
// ===================================================================
import FlexBox from '/component/base/FlexBox/FlexBox';

export const BackgroundImage = styled.div`
  background-color: ${(props) => props.backgroundColor};
  background-image: url(${(props) => props.backgroundImage});
  background-position: ${(props) => props.backgroundPositionHorizontal || 0}px
    ${(props) => props.backgroundPositionVertical || 0}px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: auto;
  width: 100%;
`;

export const BaseLayoutContainer = styled(FlexBox.Row)`
  align-items: stretch;
  border-bottom: ${rem(16)} white solid;
  border-left: ${(props) => !props.withMenu && `${rem(16)} white solid`};
  border-right: ${rem(16)} white solid;
  border-top: ${rem(16)} white solid;
  border-color: ${(props) =>
    props.borderColor ||
    'white'}; // eslint-disable sort-declarations-alphabetically
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    border-bottom: ${rem(8)} white solid;
    border-left: ${(props) => !props.withMenu && `${rem(8)} white solid`};
    border-right: ${rem(8)} white solid;
    border-top: ${rem(8)} white solid;
    border-color: ${(props) => props.borderColor || 'white'};
  }
`;

export const MainContent = styled.div`
  background-color: ${({ theme, color, opacity }) =>
    color && rgba(theme.colors[color], opacity || 1)};
  ${({ gradient }) => gradient && `background: ${gradient}`};
  height: 100%;
  overflow: auto;
  width: 100%;
`;
