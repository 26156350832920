// ===================================================================
// Import modules
// ===================================================================
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { fonts, textStyles } from '/config/theme.config';
import { typography, variant, layout, border, space } from 'styled-system';

export const withTextLinkStyles = ({ textLink }) => {
  if (textLink) {
    return `
      cursor: pointer;
      text-decoration: underline;
    `;
  }

  return '';
};

export const withHover = ({ hover, hoverBorderBottom, theme }) => {
  if (hover) {
    return `
      :hover {
        ${hoverBorderBottom ? `border-bottom: ${hoverBorderBottom};` : ''}
        color: ${theme.colors.red};
      }
    `;
  }

  return '';
};

const styles = css`
  /* Adds red hover text */
  ${withHover}

  /* Generate styles for an href */
  ${withTextLinkStyles}

  /* Allow bold subtitle headers. */
  b {
    font-family: ${fonts.untitledSerifMed};
  }

  /* Indented and bulleted list */
  ul.indentedList {
    list-style-position: outside;

    li {
      margin-bottom: 1rem;
      margin-left: 30px;
    }
  }

  /* styled systems props */
  ${variant({
    prop: 'textStyle',
    variants: textStyles
  })}
  ${border}
  ${layout}
  ${space}
  ${typography}

  /* Also allow overriding individual styles. */
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
  ${({ theme, backgroundColor, alpha }) =>
    backgroundColor &&
    `background-color: ${rgba(theme.colors[backgroundColor], alpha)}`};
  ${({ theme, color, alpha }) =>
    color && `color: ${rgba(theme.colors[color], alpha)}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ mixBlendMode }) => mixBlendMode && `mixBlendMode: ${mixBlendMode}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ decoration }) => decoration && `text-decoration: ${decoration}`};
  ${({ preline }) => preline && 'white-space: pre-line'};
  ${({ wordWrap }) => wordWrap && `word-wrap: ${wordWrap}`};
`;

export const TextSpan = styled.span`
  ${styles}
`;

export const TextDiv = styled.div`
  ${styles}
`;
