// ===================================================================
// Import modules
// ===================================================================
import React, { createContext, useContext, useState, useRef } from 'react';

// ===================================================================
// Import components
// ===================================================================
import StandardBanner from '/component/banner/StandardBanner/StandardBanner';
import { BannersContainer } from './BannerProvider.style';

const BannerContext = createContext();

export const useBanner = () => useContext(BannerContext);

export const BASE_TIMEOUT = 2500;

export function BannerProvider({ children }) {
  const [banners, setBanners] = useState([]);
  const bannersRef = useRef(banners);
  bannersRef.current = banners;

  const closeAllBanners = () => {
    setBanners([]);
  };

  const closeBanner = (id) => {
    setBanners(bannersRef.current.filter((banner) => id !== banner.id));
  };

  const showBanner = ({ message, timeout }) => {
    const id =
      Math.random()
        .toString(36)
        .substring(2) + new Date().getTime().toString(36);
    setBanners([...bannersRef.current, { id, message, timeout }]);

    return id;
  };

  return (
    <BannerContext.Provider
      value={{
        closeAllBanners: closeAllBanners,
        closeBanner: closeBanner,
        showBanner: showBanner
      }}>
      {children}
      {banners !== 0 && (
        <BannersContainer>
          {banners.map((banner) => (
            <StandardBanner
              banner={banner}
              closeBanner={() => closeBanner(banner.id)}
              key={banner.id}
            />
          ))}
        </BannersContainer>
      )}
    </BannerContext.Provider>
  );
}

export default BannerProvider;
