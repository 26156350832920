// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import styled from 'styled-components';
import {
  BasePropTypes,
  StringPropTypes,
  NumberPropTypes
} from '/proptypes/styledPropTypes';

import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography
} from 'styled-system';

const Element = styled.div`
  ${({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing}`};
  ${({ visibility }) => visibility && `visibility: ${visibility}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${border};
  ${color};
  ${flexbox};
  ${layout};
  ${position};
  ${space};
  ${typography};
`;

export const Box = (props) => <Element {...props} />;

Box.propTypes = {
  boxSizing: BasePropTypes,

  /** styled-systems space */
  /** https://styled-system.com/table#space */

  /** (short name) margin all sides */
  m: BasePropTypes,
  /** (short name) margin top */
  mt: BasePropTypes,
  /** (short name) margin right */
  mr: BasePropTypes,
  /** (short name) margin bottom */
  mb: BasePropTypes,
  /** (short name) margin left */
  ml: BasePropTypes,
  /** (short name) margin top and bottom */
  my: BasePropTypes,
  /** (short name) margin left and right */
  mx: BasePropTypes,
  /** margin all sides */
  margin: BasePropTypes,
  /** margin top */
  marginTop: BasePropTypes,
  /** margin right */
  marginRight: BasePropTypes,
  /** margin bottom */
  marginBottom: BasePropTypes,
  /** margin left */
  marginLeft: BasePropTypes,
  /** (short name) padding all sides */
  p: BasePropTypes,
  /** (short name) padding top */
  pt: BasePropTypes,
  /** (short name) padding right */
  pr: BasePropTypes,
  /** (short name) padding bottom */
  pb: BasePropTypes,
  /** (short name) padding left */
  pl: BasePropTypes,
  /** (short name) padding top and bottom */
  py: BasePropTypes,
  /** (short name) padding left and right */
  px: BasePropTypes,
  /** padding all sides */
  padding: BasePropTypes,
  /** padding top */
  paddingTop: BasePropTypes,
  /** padding right */
  paddingRight: BasePropTypes,
  /** padding bottom */
  paddingBottom: BasePropTypes,
  /** padding left */
  paddingLeft: BasePropTypes,

  /** styled-systems color */
  /** https://styled-system.com/table#color */

  /** color */
  color: StringPropTypes,
  /** background color */
  backgroundColor: StringPropTypes,
  /** opacity */
  opacity: NumberPropTypes,

  /** styled-systems typography */
  /** https://styled-system.com/table#typography */

  /** font family */
  fontFamily: StringPropTypes,
  /** font size */
  fontSize: BasePropTypes,
  /** font weight */
  fontWeight: BasePropTypes,
  /** line height */
  lineHeight: BasePropTypes,
  /** letter spacing */
  letterSpacing: BasePropTypes,
  /** text align */
  textAlign: StringPropTypes,
  /** font style */
  fontStyle: StringPropTypes,

  /** styled-systems layout */
  /** https://styled-system.com/table#layout */

  /** width */
  width: BasePropTypes,
  /** height */
  height: BasePropTypes,
  /** min width */
  minWidth: BasePropTypes,
  /** max width */
  maxWidth: BasePropTypes,
  /** min height */
  minHeight: BasePropTypes,
  /** max height */
  maxHeight: BasePropTypes,
  /** size */
  size: BasePropTypes,
  /** display */
  display: StringPropTypes,
  /** vertical align */
  verticalAlign: NumberPropTypes,
  /** overflow */
  overflow: StringPropTypes,

  /** styled-systems flexbox */
  /** https://styled-system.com/table#flexbox */

  /** align items */
  alignItems: StringPropTypes,
  /** align content */
  alignContent: StringPropTypes,
  /** justify items */
  justifyItems: StringPropTypes,
  /** justify content */
  justifyContent: StringPropTypes,
  /** flex wrap */
  flexWrap: StringPropTypes,
  /** flex direction */
  flexDirection: StringPropTypes,
  /** flex */
  flex: BasePropTypes,
  /** flex grow */
  flexGrow: BasePropTypes,
  /** flex shrink */
  flexShrink: BasePropTypes,
  /** flex basis */
  flexBasis: BasePropTypes,
  /** justify self */
  justifySelf: StringPropTypes,
  /** align self */
  alignSelf: StringPropTypes,
  /** order */
  order: BasePropTypes,

  /** styled-systems border */
  /** https://styled-system.com/table#border */

  /** border */
  border: StringPropTypes,
  /** border width */
  borderWidth: BasePropTypes,
  /** border style */
  borderStyle: StringPropTypes,
  /** border color */
  borderColor: StringPropTypes,
  /** border radius */
  borderRadius: BasePropTypes,
  /** border top */
  borderTop: BasePropTypes,
  /** border right */
  borderRight: BasePropTypes,
  /** border bottom */
  borderBottom: BasePropTypes,
  /** border left */
  borderLeft: BasePropTypes,
  /** border left and right */
  borderX: BasePropTypes,
  /** border top and bottom */
  borderY: BasePropTypes,

  /** styled-systems position */
  /** https://styled-system.com/table#position */

  /** position */
  position: StringPropTypes,
  /** z-index */
  zIndex: BasePropTypes,
  /** top */
  top: BasePropTypes,
  /** right */
  right: BasePropTypes,
  /** bottom */
  bottom: BasePropTypes,
  /** left */
  left: BasePropTypes
};

export default Box;
