// ===================================================================
// Import modules
// ===================================================================
import React from 'react';
import PropTypes from 'prop-types';

// ===================================================================
// Import view components
// ===================================================================
import Text from '/component/base/Text/Text';

// ===================================================================
// Import styles
// ===================================================================
import { HeadingContainer, SubHeadingContainer } from './Heading.style';

// ===================================================================
// Import config
// ===================================================================
import { colors, headingStyles } from '/config/theme.config';

const Heading = ({
  textAlign,
  color,
  headingStyle,
  lineHeight,
  marginRight,
  marginTop,
  letterSpacing,
  subHeading,
  text,
  width,
  ...props
}) => (
  <HeadingContainer
    headingStyle={headingStyle}
    marginTop={marginTop}
    textAlign={textAlign}
    width={width}>
    <Text
      color={color}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      marginRight={marginRight}
      textAlign={textAlign}
      textStyle={{
        default:
          headingStyles[headingStyle].mobileHeadingStyle ||
          headingStyles[headingStyle].headingStyle,
        desktop: headingStyles[headingStyle].headingStyle
      }}
      {...props}>
      {text}
    </Text>
    {!!subHeading && (
      <SubHeadingContainer headingStyle={headingStyle} textAlign={textAlign}>
        <Text
          color={color}
          textAlign={textAlign}
          textStyle={{
            default:
              headingStyles[headingStyle].mobileSubHeadingStyle ||
              headingStyles[headingStyle].subHeadingStyle,
            desktop: headingStyles[headingStyle].subHeadingStyle
          }}>
          {subHeading}
        </Text>
      </SubHeadingContainer>
    )}
  </HeadingContainer>
);

Heading.propTypes = {
  align: PropTypes.oneOf(['left', 'right', 'center']),
  color: PropTypes.oneOf(Object.keys(colors)),
  headingStyle: PropTypes.oneOf(Object.keys(headingStyles)),
  marginTop: PropTypes.number,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string.isRequired
};

Heading.defaultProps = {
  color: 'black',
  headingStyle: 'default',
  align: 'left'
};

export default Heading;
