// ===================================================================
// Import modules
// ===================================================================
import styled from 'styled-components';

// ===================================================================
// Import styles
// ===================================================================
import Box from '/component/base/Box/Box';

export const FlexBox = styled(Box).attrs({
  display: 'flex'
})``;

FlexBox.Column = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between'
})``;

FlexBox.Row = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between'
})``;

export default FlexBox;
